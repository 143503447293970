import React from 'react'
type IconProps = {
	className?: string
}

const ArrowUp: React.FC<IconProps> = ({ className }) => (
	<svg className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
		<path d="M0 0h24v24H0z" fill="none" />
		<path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" fill="#ffffff" />
	</svg>
)
export default ArrowUp
