import React, { useEffect, useState } from 'react'
import PlacesAutocomplete, {
	geocodeByAddress,
	getLatLng,
	PropTypes as PlacesAutocompleteProps
} from 'react-places-autocomplete'
import { WebformCustomComponent, WebformElementWrapper, useWebformElement } from 'gatsby-drupal-webform'
import styled from '@emotion/styled'

import { classNames } from '../../../utils'

const SEARCH_OPTIONS = {
	types: ['(cities)']
}

const StyledAutocompleteContainer = styled.div`
	background-color: #fafafa;
	border-bottom: honeydew;
	border-left: honeydew;
	border-right: honeydew;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	border-radius: 0 0 2px 2px;
	position: absolute;
	z-index: 100;
	min-width: 10rem;
	color: black;

	.suggestion {
		padding: 8px;
		cursor: pointer;

		&.active {
			background-color: #ffffff;
		}
	}
`

const Spinner = styled.div``

const useGoogleAPI = (onLoadCallback?: () => void) => {
	const { GATSBY_GOOGLE_MAPS_API_KEY } = process.env

	useEffect(() => {
		// Ignore on server side rendering.
		if (typeof window !== 'undefined') {
			// Google API already mounted.
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
			if ((window as any).google) {
				onLoadCallback && onLoadCallback()
			} else {
				// Global function that is invoked by google API once it is ready.
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				;(window as any).googleAPICallback = () => {
					onLoadCallback && onLoadCallback()
				}

				const script = document.createElement('script')
				script.type = 'text/javascript'
				script.src = `https://maps.google.com/maps/api/js?libraries=places&callback=googleAPICallback&key=${GATSBY_GOOGLE_MAPS_API_KEY}`
				script.defer = true
				document.head.appendChild(script)

				return () => {
					// Cleanup for global function
					// eslint-disable-next-line @typescript-eslint/no-explicit-any
					;(window as any).googleAPICallback && delete (window as any).googleAPICallback
				}
			}
		}
	}, [])
}

const WebformLocationAutocomplete: WebformCustomComponent = ({ element, error }) => {
	const [customError, setCustomError] = useState<string | undefined>()

	useGoogleAPI(() => {
		// Call PlacesAutocomplete initialization callback.
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
		;(window as any).placesAutocompleteInit && (window as any).placesAutocompleteInit()
	})

	const [value, setValue] = useState('')

	const handleSelect: PlacesAutocompleteProps['onSelect'] = address => {
		setValue(address)

		geocodeByAddress(address)
			.then(results => getLatLng(results[0]))
			.then(latLng => {
				customError && setCustomError(undefined) // Clear error

				const lat = document.querySelector<HTMLInputElement>('input[name="lat"]')
				const lng = document.querySelector<HTMLInputElement>('input[name="lng"]')

				if (lat && lng) {
					lat.value = latLng.lat.toString()
					lng.value = latLng.lng.toString()
				}
			})
			.catch(error => setCustomError(`Google Maps error: ${error}`))
	}

	const onError: PlacesAutocompleteProps['onError'] = (status, clearSuggestions) => {
		setCustomError(`Google Maps error: ${status}`)
		clearSuggestions()
	}

	const [webformInputProps, settings] = useWebformElement(element, {
		className: 'form-control',
		name: element.name
	})

	return (
		/**
		 * @todo Should this accept location on blur like vegaanihaaste.fi does.
		 * @see https://github.com/oikeuttaelaimille/vegaanihaaste-test/blob/master/frontend/src/components/Form/LocationInput.tsx#L85-L104
		 */
		<PlacesAutocomplete
			value={value}
			onChange={setValue}
			onSelect={handleSelect}
			onError={onError}
			searchOptions={SEARCH_OPTIONS}
			highlightFirstSuggestion
			googleCallbackName="placesAutocompleteInit"
		>
			{({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
				<WebformElementWrapper settings={settings} error={error || customError}>
					<input
						{...getInputProps({
							...webformInputProps
						})}
					/>

					<StyledAutocompleteContainer className="autocomplete-container">
						{loading && (
							/**
							 * @todo Loading spinner
							 */
							<div>
								Loading...
								<Spinner />
							</div>
						)}
						{suggestions.map(suggestion => (
							// Key parameter should be handled by getSuggestionItemProps().
							// eslint-disable-next-line react/jsx-key
							<div
								{...getSuggestionItemProps(suggestion, {
									className: classNames('suggestion', suggestion.active && 'active')
								})}
							>
								{suggestion.description}
							</div>
						))}
					</StyledAutocompleteContainer>
				</WebformElementWrapper>
			)}
		</PlacesAutocomplete>
	)
}

export default WebformLocationAutocomplete
