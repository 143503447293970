import styled from '@emotion/styled'
import React from 'react'
import { media } from '../style/variables'
import { Tag } from './paragraphs/FeaturedContent'

const Container = styled.div`
	display: flex;
	flex-direction: column;
`
const TitleWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin-bottom: 10px;
`
const StyledButton = styled.button`
	background-color: white;
	border: solid 1px #e5e4e4;
	border-radius: 50px;
	border-width: 1px;
	margin-right: 15px;
	width: 35px;
	height: 35px;
	${media.up('md')} {
		margin-left: -0px;
	}
	:after {
		content: '';
		display: block;
		border-style: solid;
		border-width: 2px;
		border-color: transparent black black transparent;
		border-radius: 0px 1px 1px 1px;
		width: 10px;
		height: 10px;
		transform: rotate(135deg);
		margin-left: -2px;
	}
	&:hover {
		background-color: #951d95;
		border-color: transparent;
		:after {
			content: '';
			display: block;
			border-style: solid;
			border-width: 2px;
			border-color: transparent white white transparent;
			border-radius: 0px 1px 1px 1px;
			width: 10px;
			height: 10px;
			transform: rotate(135deg);
			margin-left: -2px;
		}
	}
`
const Title = styled.h1`
	font-size: 20px;
	margin-bottom: 0;
	margin-left: 3px;
	${media.up('md')} {
		font-size: 22px;
	}
	${media.up('lg')} {
		font-size: 24px;
	}
`
const Description = styled.div`
	p {
		font-size: 12px;
		margin-bottom: 10px;
		margin-top: 4px;
		margin-left: 12px;
		${media.up('md')} {
			font-size: 1rem;
			margin-left: 0px;
			margin-top: 8px;
		}
		${media.up('lg')} {
			font-size: 1rem;
			margin-left: 0px;
			margin-top: 10px;
		}
	}
`

export type DescriptionProps = {
	tag?: Tag
	onClickButton: () => void
}

const CategoryDescription: React.FC<DescriptionProps> = props => {
	const { tag, onClickButton } = props
	if (tag) {
		return (
			<Container key={tag.id}>
				<TitleWrapper>
					<StyledButton onClick={onClickButton} title="back button"></StyledButton>
					<Title>{tag.name}</Title>
				</TitleWrapper>
				{tag.description?.processed && <Description dangerouslySetInnerHTML={{ __html: tag.description.processed }} />}
			</Container>
		)
	}
	return null
}
export default CategoryDescription
