import React from 'react'
import { MultiList } from '@appbaseio/reactivesearch'
import styled from '@emotion/styled'
import { fonts } from '../../style/variables'

const StyledMultiList = styled(MultiList)`
	padding: 0;
	ul {
		max-height: 100%;
		width: 100%;
	}
	.label {
		font-size: 14px;
	}
	.title {
		font-family: ${fonts.secondary};
		font-weight: 600;
		font-size: 0.9rem;
		margin: 0 0 14px;
	}
`

type FilterProps = {
	componentId: string
	dataField: string
}

const Filter: React.FC<FilterProps> = props => {
	const { componentId, dataField } = props
	return (
		<StyledMultiList
			title="Rajaa"
			componentId={componentId}
			dataField={dataField}
			showCheckbox={true}
			showFilter={true}
			showSearch={false}
			sortBy="asc"
			URLParams={true}
			queryFormat="and"
			loader="Loading ..."
			innerClass={{
				label: 'label',
				title: 'title'
			}}
			showCount={false}
			react={{
				and: ['searchbox']
			}}
		/>
	)
}
export default Filter
