import styled from '@emotion/styled'

/**
 * @todo Refactor Stack.tsx to work with two column signup forms.
 * This file was created on hurry to rescue the Slick slider to work.
 * This is used only for Two column signup form.
 * @see https://every-layout.dev/layouts/stack/
 */
export default styled.div`
	display: flex;
	flex-wrap: wrap;
	margin-left: -10px;
	margin-right: -10px;

	> * {
		margin-top: 0;
		margin-left: 10px;
		margin-right: 10px;
		flex: 1 1 100%;
		margin-bottom: var(--spacing);
	}

	> *:last-child {
		margin-bottom: 0;
	}

	> .container-inline {
		flex: 1 1 45%;
	}

	/** Consecutive full width elements have no margin. */
	> .full-width + .full-width {
		margin-top: 0;
	}
`
