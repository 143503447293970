import React, { useState } from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { ReactiveBase } from '@appbaseio/reactivesearch'
import { Paragraph, ParagraphComponent } from './types'
import { media } from '../../style/variables'
import Search from '../RecipeSearch/Search'
import Filter from '../RecipeSearch/Filter'
import List from '../RecipeSearch/List'
import Sidebar from '../RecipeSearch/SideBar'
import FilterButton from '../RecipeSearch/FilterButton'
import ScrollTopButton from '../RecipeSearch/ScrollTop'
import VcLogo from '../Icon/VcLogo'

export interface RecipeSearchParagraph extends Paragraph {}

const StyledContainer = styled.div`
	width: 100%;
	padding: 0 10px 30px;
	max-width: 2200px;
	align-self: center;
	${media.up('md')} {
		padding: 0 30px 50px;
	}
`
const HeaderWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	position: relative;
	margin-bottom: 15px;
	margin-left: 10px;
	${media.up('sm')} {
		margin-left: 10px;
		justify-content: space-between;
	}
	${media.up('md')} {
		margin-left: 0px;
		justify-content: space-between;
	}
`
const LogoWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	${media.up('md')} {
		display: none;
	}
`
const Logo = styled(VcLogo)`
	height: 40px;
	width: auto;
	color: rgb(var(--clr-primary));
	margin-right: 10px;
	${media.up('sm')} {
		height: 50px;
		margin-right: 20px;
	}
`
const Heading = styled.h1`
	font-weight: 600;
	font-size: 22px;
	margin: 0;
	${media.up('sm')} {
		font-size: 26px;
	}
`
export const FilterWrapper = styled.div`
	position: fixed;
	bottom: 15px;
	left: 50%;
	transform: translateX(-50%);
	z-index: 998;
`
const ContentWrapper = styled.div`
	display: flex;
	margin-top: 15px;
	${media.up('md')} {
		margin-top: 25px;
	}
`
const ListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;
`

export type RecipeResult = {
	title: string
	title_string: string
	_id: string
	image_url: string
	tag_name_string: string
	tag_name: string
	changed: string
	url: string
}

const RecipeSearch: ParagraphComponent<'paragraph__recipe_search', RecipeSearchParagraph> = ({}) => {
	const [open, setOpen] = useState(false)

	const onHandleOpen = () => {
		setOpen(!open)
	}
	const onHandleClose = () => setOpen(false)

	return (
		<StyledContainer>
			<section className="paragraph full-width">
				<ReactiveBase
					url={process.env.GATSBY_ELASTIC_API}
					app="vc_fi_recipes"
					theme={{
						colors: {
							primaryColor: '#a03092',
							titleColor: '#333334',
							fontFamily:
								'apple-system,BlinkMacSystemFont,"Segoe UI","Roboto","Oxygen","Ubuntu","Cantarell","Fira Sans","Droid Sans","Helvetica Neue",Arial,sans-serif'
						},
						component: {
							padding: 10
						}
					}}
				>
					<HeaderWrapper>
						<LogoWrapper>
							<a href="/" title="Linkki osallistu haasteeseen sivulle">
								<Logo></Logo>
							</a>
							<Heading>Reseptit</Heading>
						</LogoWrapper>
						<FilterWrapper>
							<FilterButton onClick={onHandleOpen} text="Rajaa" />
						</FilterWrapper>
					</HeaderWrapper>
					<Search componentId="searchbox" dataField={['title', 'tag_name']} />
					<ContentWrapper>
						<Sidebar open={open} onHandleClose={onHandleClose}>
							<Filter componentId="checkbox" dataField="tag_name_string" />{' '}
						</Sidebar>
						<ListWrapper>
							<List componentId="results" dataField="title" changed="changed" title_string="title_string" />
						</ListWrapper>
					</ContentWrapper>
				</ReactiveBase>
			</section>
			<ScrollTopButton />
		</StyledContainer>
	)
}

export default RecipeSearch

export const query = graphql`
	fragment RecipeSearchParagraph on paragraph__recipe_search {
		drupal_internal__id
	}
`
