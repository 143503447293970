import React, { ReactNode } from 'react'
import styled from '@emotion/styled'

const TooltipText = styled.span`
	visibility: hidden;
	background-color: #f1efef;
	color: rgb(var(--clr-primary));
	text-align: center;
	padding: 4px 6px;
	position: absolute;
	border-radius: 2px;
	top: -70px;
	left: -50px;
	z-index: 10000;
`

const Container = styled.div`
	display: flex;
	margin: 3px;
	position: relative;
	border-color: transparent;
	border-radius: 50px;
	background-color: #f1efef;
	width: 22px;
	height: 22px;
	justify-content: center;
	align-items: center;
	&:hover {
		${TooltipText} {
			visibility: visible;
		}
	}
	&:first-of-type {
		margin-left: 0;
	}
`

type FlagProps = {
	tooltip?: string
	children?: ReactNode
}

const Flag: React.FC<FlagProps> = ({ tooltip, children }) => {
	return (
		<Container tabIndex={0} title={tooltip}>
			{' '}
			{children}
			<TooltipText>{tooltip}</TooltipText>
		</Container>
	)
}

export default Flag
