import React from 'react'
import { Link } from 'gatsby'

import { DrupalLink } from '../../drupal'

interface Props {
	link: DrupalLink
}

const Button: React.FC<Props> = ({ link }) =>
	// Selects which component to use by determining if link is internal or external.
	// Gatsby Link component should be used for internal navigation.
	// https://www.gatsbyjs.org/docs/gatsby-link/
	link.alias ? (
		<Link to={link.alias} className="btn">
			{link.title}
		</Link>
	) : (
		<a href={link.uri} className="btn">
			{link.title}
		</a>
	)

export default Button
