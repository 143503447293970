import React from 'react'
import Slider, { Settings as SlickSettings } from 'react-slick'
import styled from '@emotion/styled'

import { media, dimensions, breakpointValues } from '../../../style/variables'
import Container from '../../Container'
import RecipeCard, { RecipeNodeTeaser } from './RecipeCard'

interface Props {
	className?: string
	title: string
	recipes: RecipeNodeTeaser[]
}

const SLICK_SETTINGS: SlickSettings = {
	dots: false,
	infinite: false,
	autoplay: false,
	draggable: false,
	speed: 500,
	slidesToShow: 6,
	slidesToScroll: 6,
	responsive: [
		{
			breakpoint: breakpointValues.lg,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4
			}
		},
		{
			breakpoint: breakpointValues.md,
			settings: {
				slidesToShow: 3,
				slidesToScroll: 3
			}
		},
		{
			breakpoint: breakpointValues.sm,
			settings: {
				slidesToShow: 2,
				slidesToScroll: 2
			}
		}
	]
}

const StyledSlider = styled(Slider)`
	margin: 0 calc(var(--slider-margin) * -1);

	.slick-list {
		overflow-x: scroll;
		overflow-y: hidden;
		margin-left: -3px;
		&::-webkit-scrollbar {
			display: none;
		}
	}

	.slick-track {
		display: flex;
		${media.up('sm')} {
			margin: 15px; // allows animation to scale
		}
	}

	.slick-arrow {
		z-index: 10;
		width: 42px;
		height: 150px;
		${media.down('xs')} {
			display: none;
		}
	}

	.slick-next {
		right: 10px;
		opacity: 1;
		&:before {
			font-size: 42px;
			color: #ffffff;
			text-shadow: 0px 0px 4px #1d1d1d;
		}
	}

	.slick-prev {
		left: 10px;
		opacity: 1;
		&:before {
			font-size: 42px;
			color: #ffffff;
			text-shadow: 0px 0px 4px #1d1d1d;
		}
	}

	.slick-slide {
		transition: transform 300ms ease 100ms;
		display: flex;
		flex-direction: column;
		height: auto;

		> * {
			flex: 1;
			padding: 0 3px;
		}
	}

	.slick-slide:hover {
		transform: scale(1.1) !important;
	}

	.slick-list:hover .slick-slide {
		transform: translateX(-6.25%);
	}

	.slick-slide:hover ~ .slick-slide {
		transform: translateX(6.25%);
	}
`

const RecipeSection: React.FC<Props> = ({ className, title, recipes }) => (
	<div className={className}>
		<Container>
			<h2>{title}</h2>
		</Container>
		<div css={{ position: 'relative', padding: `0 var(--slider-margin)` }}>
			<StyledSlider {...SLICK_SETTINGS}>
				{recipes.map(node => (
					<RecipeCard key={node.drupal_internal__nid} recipe={node} />
				))}
			</StyledSlider>
		</div>
	</div>
)

export default styled(RecipeSection)`
	--slider-margin: 1.5rem;

	${media.up('sm')} {
		--slider-margin: 2rem;
	}

	${media.up('md')} {
		--slider-margin: 2.5rem;
	}

	${media.up('lg')} {
		--slider-margin: 3.5rem;
	}

	/** Vertical padding is needed for scale animation. */
	padding: ${dimensions.containerPadding * 2}rem 0;
	overflow: hidden;

	.container {
		padding: 0 var(--slider-margin);
	}
`
