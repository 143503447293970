/* eslint-disable @typescript-eslint/no-non-null-assertion */

import { useRef } from 'react'

const createObserver = (callback: IntersectionObserverCallback, options?: IntersectionObserverInit) => {
	// Server side rendering or IntersectionObserver is not supported.
	if (typeof window === 'undefined' || !('IntersectionObserver' in window)) {
		console.error('IntersectionObserver is not supported')
		return null
	}

	return new IntersectionObserver(callback, options)
}

const useIntersectionObserver = (callback: IntersectionObserverCallback, options?: IntersectionObserverInit) => {
	const ref = useRef<IntersectionObserver | null>(null)

	// ✅ IntersectionObserver is created lazily once
	function getObserver() {
		if (ref.current === null) {
			ref.current = createObserver(callback, options)
		}

		return ref.current!
	}

	return getObserver()
}

export default useIntersectionObserver
