import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import Img from 'gatsby-image'
import { Tag } from '../paragraphs/FeaturedContent'

const Container = styled.div`
	min-height: unset;
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	will-change: transform, box-shadow;
	transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
	cursor: pointer;
	text-decoration: none;
	&:hover {
		transform: translateY(-4px);
		box-shadow: 0 0px 20px rgb(0 0 0 / 25%), 0 7px 10px rgb(0 0 0 / 22%);
		text-decoration: none;
	}
	&:active {
		transform: translateY(0px);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
	}
`

const StyledImg = styled(Img)`
	display: block;
	object-fit: cover;
	width: 100%;
	height: 100%;
	border-radius: 3px;
	@supports (aspect-ratio: 1 / 1) {
		aspect-ratio: 1 / 1;
	}
	@supports not (aspect-ratio: 1 / 1) {
		&:before {
			float: left;
			padding-top: 100%;
			content: '';
		}
	}
`
const TitleWrapper = styled.div`
	position: absolute;
	justify-content: flex-start;
	min-height: 60px;
	z-index: 100;
	background-color: #fffdfbb9;
	flex-direction: column;
	border-radius: 3px;
	top: 50%;
	left: 5%;
	right: 5%;
	padding: 10px;
	backdrop-filter: blur(4px);
	${media.up('sm')} {
		top: 58%;
		left: 5%;
		right: 5%;
	}
	${media.up('lg')} {
		min-height: 80px;
		top: 60%;
		padding: 15px;
	}
`
const Title = styled.h2`
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	font-weight: bold;
	line-height: 1.4;
	letter-spacing: 1.1px;
	margin: 0;
	color: black;
	text-decoration: none;
	font-size: 0.8rem;
	${media.up('lg')} {
		font-size: 1rem;
	}
`

export type CardProps = {
	tag: Tag
	onClick: (t: Tag | undefined) => void
}

export default function Card(props: CardProps) {
	const { tag, onClick } = props
	const { relationships, name } = tag
	const fluidImage =
		relationships?.field_category_image?.relationships.field_media_image.localFile.childImageSharp.fluid

	return (
		<Container onClick={() => onClick(tag)}>
			{fluidImage && <StyledImg fluid={fluidImage} alt="noodles" />}
			<TitleWrapper>
				<Title>{name}</Title>
			</TitleWrapper>
		</Container>
	)
}
