import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import Img from 'gatsby-image'

import { Paragraph, ParagraphComponent } from '../types'
import { DrupalProcessedText, DrupalColor, DrupalLink, DrupalMediaImage } from '../../../drupal'
import { backgroundColorStyle } from '../../../utils'
import Button from '../../blocks/Button'

import { dimensions, widths, media } from '../../../style/variables'

export interface CallToAction1ColParagraph extends Paragraph {
	field_background_color?: DrupalColor
	field_body?: DrupalProcessedText
	field_link?: DrupalLink
	relationships: {
		img_left?: DrupalMediaImage
		img_right?: DrupalMediaImage
	}
}

const StyledCallToAction = styled.div`
	--spacing: ${dimensions.containerPadding / 2}rem;

	height: 100%;
	padding-top: ${dimensions.containerPadding}rem;
	padding-bottom: ${dimensions.containerPadding}rem;
	display: flex;
	position: relative;
	justify-content: space-between;

	/** @todo: better way to define colors buttons. */
	.btn,
	button {
		color: white;
	}

	.decoration {
		align-self: center;
	}

	.cta-text {
		width: 400px;
	}

	.cta-main {
		padding-left: ${dimensions.containerPadding}rem;
		padding-right: ${dimensions.containerPadding}rem;

		/** Always above .decoration */
		position: relative;
		z-index: 10;
	}

	/**
	 * Don't ask me what this is supposed to do ¯\_(ツ)_/¯.
	 * @fixme I think this only looks with my testing images.
	 */
	${media.down('md')} {
		.cta-main {
			margin: 0 auto;
			width: ${widths.md}px;
		}

		.decoration-left {
			display: none;
		}

		.decoration-right {
			position: absolute;
			opacity: 0.5;
			right: 0;
		}

		.cta-text {
			width: 400px;
		}
	}

	${media.down('sm')} {
		.cta-main {
			width: ${widths.sm}px;
		}

		.cta-text {
			width: 350px;
		}
	}

	${media.down('xs')} {
		.cta-main {
			width: 100%;
		}

		.cta-text {
			width: 100%;
		}

		.decoration-right {
			display: none;
		}
	}
`

const CallToAction1Col: ParagraphComponent<'paragraph__call_to_action_single_column', CallToAction1ColParagraph> = ({
	paragraph
}) => (
	<section className="paragraph full-width" style={backgroundColorStyle(paragraph.field_background_color)}>
		<StyledCallToAction className="call-to-action columns-one">
			{paragraph.relationships.img_left && (
				<div className="decoration decoration-left">
					<Img alt="" {...paragraph.relationships.img_left.relationships.field_media_image.localFile.childImageSharp} />
				</div>
			)}
			<div className="cta-main">
				<div className="cta-text">
					{paragraph.field_body && <div dangerouslySetInnerHTML={{ __html: paragraph.field_body.processed }} />}

					{paragraph.field_link && <Button link={paragraph.field_link} />}
				</div>
			</div>
			{paragraph.relationships.img_right && (
				<div className="decoration decoration-right">
					<Img
						alt=""
						{...paragraph.relationships.img_right.relationships.field_media_image.localFile.childImageSharp}
					/>
				</div>
			)}
		</StyledCallToAction>
	</section>
)

export default CallToAction1Col

export const query = graphql`
	fragment CallToAction1ColParagraph on paragraph__call_to_action_single_column {
		drupal_internal__id
		field_body {
			processed
		}
		field_background_color {
			color
		}
		field_link {
			title
			alias
			uri
		}
		relationships {
			img_left: field_media_image {
				field_media_image {
					alt
				}
				relationships {
					field_media_image {
						localFile {
							extension
							publicURL
							childImageSharp {
								fixed(height: 300) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
			img_right: field_media_image_2 {
				field_media_image {
					alt
				}
				relationships {
					field_media_image {
						localFile {
							extension
							publicURL
							childImageSharp {
								fixed(height: 300) {
									...GatsbyImageSharpFixed
								}
							}
						}
					}
				}
			}
		}
	}
`
