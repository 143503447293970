import React from 'react'
import styled from '@emotion/styled'
import { fonts, media } from '../../style/variables'

const Container = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: rgb(var(--clr-primary));
	align-items: center;
	justify-content: center;
	position: relative;
	height: 60px;
	${media.up('lg')} {
		display: none;
	}
`
const StyledTitle = styled.span`
	color: white;
	font-weight: 600;
	font-size: 20px;
	font-family: ${fonts.primary};
	letter-spacing: 1.2px;
`
const StyledIconButton = styled.button`
	background-color: rgb(var(--clr-primary));
	border: none;
	padding: 0;
	&:after {
		content: '×';
		color: white;
		text-decoration: none;
		font-size: 26px;
		font-weight: 400;
		background-color: rgb(var(--clr-primary));
	}
	&:hover {
		background-color: rgb(var(--clr-primary));
	}
	${media.up('lg')} {
		display: none;
	}
	position: absolute;
	right: 20px;
	margin-top: 0;
	line-height: 0;
	z-index: 1100;
`

export type CloseProps = {
	onHandleClose: () => void
	open: boolean
}

const Close: React.FC<CloseProps> = props => {
	const { onHandleClose } = props
	return (
		<Container>
			<StyledTitle>Reseptit</StyledTitle>
			<StyledIconButton onClick={onHandleClose}></StyledIconButton>
		</Container>
	)
}

export default Close
