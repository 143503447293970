import { useEffect, useRef } from 'react'
const useClickOutside = <Element extends HTMLElement>(callback: () => void) => {
	const ref = useRef<Element>(null)
	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (ref.current && event.target instanceof HTMLElement && !ref.current.contains(event.target)) {
				callback()
			}
		}
		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [ref, callback])
	return ref
}

export default useClickOutside
