import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import Card from './Card'
import { fadeIn, scaleIn } from './animations'
import { RecipeResult } from '../paragraphs/RecipeSearch'
import css from '@emotion/css'
import { LazyComponentProps, ScrollPosition, trackWindowScroll } from 'react-lazy-load-image-component'

const animation = () =>
	Array(30)
		.fill('')
		.map(
			(_, i) => css`
				a:nth-of-type(${i}) {
					animation: ${fadeIn} 1000ms ${i * 30}ms backwards, ${scaleIn} 1s ${i * 20}ms backwards;
				}
			`
		)

const GridContainer = styled.div`
	display: grid;
	width: 100%;
	margin: 10px 0;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	grid-auto-flow: row dense;
	${media.up('sm')} {
		grid-template-columns: repeat(auto-fill, minmax(140px, 1fr));
		grid-gap: 12px;
		margin: 12px 0;
	}
	${media.up('md')} {
		grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
		grid-gap: 12px;
	}
	${media.up('lg')} {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
		grid-gap: 14px;
	}
	${animation()}
`

const getPathName = (url: string) => new URL(url).pathname

export type GridProps = {
	recipes: RecipeResult[]
	scrollPosition: ScrollPosition | undefined
}

const Grid: React.FC<GridProps & LazyComponentProps> = ({ recipes, scrollPosition }) => {
	if (recipes && recipes.length > 0) {
		return (
			<GridContainer id="content">
				{recipes.map(({ _id, image_url: imageUrl, title, url, tag_name_string }) => {
					return (
						<Card
							key={_id}
							id={_id}
							image={imageUrl}
							title={title}
							href={getPathName(url)}
							// eslint-disable-next-line @typescript-eslint/camelcase
							attributes={tag_name_string}
							scrollPosition={scrollPosition}
						/>
					)
				})}
			</GridContainer>
		)
	}
	return null
}

export default trackWindowScroll(Grid)
