import React from 'react'
import { graphql } from 'gatsby'
import { Paragraph, ParagraphComponent } from './types'
import { DrupalColor, DrupalBanner } from '../../drupal'
import Banner from '../Banner'

export interface HeroBannerParagraph extends Paragraph {
	field_background_color?: DrupalColor
	relationships: {
		field_media_image?: DrupalBanner
	}
}

const HeroBanner: ParagraphComponent<'paragraph__hero_banner', HeroBannerParagraph> = ({ paragraph }) => (
	<Banner
		className="paragraph full-width"
		backgroundColor={paragraph.field_background_color}
		image={paragraph.relationships.field_media_image}
	/>
)

export default HeroBanner

export const query = graphql`
	fragment HeroBannerParagraph on paragraph__hero_banner {
		drupal_internal__id
		field_background_color {
			color
		}
		relationships {
			field_media_image {
				...DrupalBanner
			}
		}
	}
`
