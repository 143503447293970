import React from 'react'
import Chip from './Chip'

type ClearAllProps = {
	showClearAll: boolean
	setValue: () => void
	filtersCount?: number
}

const ClearAll: React.FC<ClearAllProps> = ({ showClearAll, setValue, filtersCount }) => {
	if (showClearAll && filtersCount && filtersCount > 1) {
		return <Chip onClick={setValue} text="Poista rajaukset" hideCloseIcon filtersCount={filtersCount} />
	}
	return null
}

export default ClearAll
