import styled from '@emotion/styled'

/**
 * @see https://every-layout.dev/layouts/stack/
 */
export default styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;

	> * {
		margin-top: 0;
		margin-bottom: 0;
	}

	> * + * {
		margin-top: var(--spacing);
	}

	/** Consecutive full width elements have no margin. */
	> .full-width + .full-width {
		margin-top: 0;
	}
`
