import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import Card from './Card'
import { Tag } from '../paragraphs/FeaturedContent'

const GridContainer = styled.div`
	display: grid;
	width: 100%;
	margin: 10px 0;
	grid-gap: 10px;
	grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
	grid-auto-flow: row dense;
	${media.up('sm')} {
		grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
		grid-gap: 12px;
		margin: 12px 0;
	}
	${media.up('md')} {
		grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
		grid-gap: 12px;
	}
	${media.up('lg')} {
		grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
		grid-gap: 16px;
	}
`

type GridProps = {
	tags?: Tag[]
	onClick: (t: Tag | undefined) => void
}

const Grid: React.FC<GridProps> = props => {
	const { tags, onClick } = props

	if (tags) {
		return (
			<GridContainer id="content">
				{tags.map(tag => {
					return <Card key={tag.id} tag={tag} onClick={onClick} />
				})}
			</GridContainer>
		)
	}
	return null
}

export default Grid
