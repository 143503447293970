import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { DrupalProcessedText } from '../../drupal'
import Container from '../Container'
import { media } from '../../style/variables'

export interface TitleParagraph extends Paragraph {
	field_paragraph_title: DrupalProcessedText
}

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 0;
	.title {
		margin-bottom: 0;
		font-size: 22px;
		padding: 0 10px;
		${media.up('sm')} {
			font-size: 24px;
			padding: 0 10px;
		}
		${media.up('md')} {
			font-size: 26px;
			padding: 0;
		}
		${media.up('lg')} {
			font-size: 28px;
			margin-top: 20px;
			padding: 0;
		}
	}
`

const Title: ParagraphComponent<'paragraph__title', TitleParagraph> = ({ paragraph }) => (
	<section className="paragraph">
		<StyledContainer
			dangerouslySetInnerHTML={{ __html: paragraph.field_paragraph_title?.processed ?? '' }}
			className="title"
		/>
	</section>
)

export default Title

export const query = graphql`
	fragment TitleParagraph on paragraph__title {
		drupal_internal__id
		field_paragraph_title {
			processed
		}
	}
`
