import React from 'react'

import { Paragraph } from './types'
import Container from '../Container'

interface Props {
	paragraph: Paragraph
}

const DebugParagraph: React.FC<Props> = ({ paragraph }) => (
	<Container className="paragraph">
		<code>
			<pre>{JSON.stringify(paragraph, null, 2)}</pre>
		</code>
	</Container>
)

export default DebugParagraph
