import type { FunctionComponent } from 'react'
import { FluidObject, FixedObject } from 'gatsby-image'

/**
 * @file Drupal types
 */

export type MetatagAttributes = {
	[key: string]: string | null
}

export type NormalizedMetatag = {
	tag: string
	attributes: MetatagAttributes
}

/**
 * Drupal color field
 */
export type DrupalColor = {
	color: string
}

/**
 * Drupal link field
 */
export type DrupalLink = {
	title: string
	uri: string
	alias?: string
}

/**
 * Drupal body field
 */
export interface DrupalProcessedText {
	processed: string
}

/**
 * Common fields required for all drupal nodes
 *
 * Field here are required by all drupal template components.
 */
export interface DrupalNode {
	langcode?: string
	title: string
	metatag_normalized: NormalizedMetatag[]
}

/**
 * Node template component type.
 *
 * All components in `src/templates` should be of this type.
 */
export interface DrupalTemplate<N extends DrupalNode, T extends {} = {}>
	extends FunctionComponent<{ data: { node: N } & T }> {}

/**
 * Common fields required for all drupal node teasers.
 */
export interface DrupalNodeTeaser {
	__typename: string
	drupal_internal__nid: number
	title: string
	path: {
		alias: string
	}
}

export interface DrupalTaxonomyTerm {
	drupal_internal__tid: number
	langcode?: string
	name: string
	description?: DrupalProcessedText
}

interface DrupalMediaBase {
	__typename: string
	drupal_internal__mid: number
}

export interface DrupalMediaGraphics extends DrupalMediaBase {
	__typename: 'media__graphics'
	field_media_image: {
		alt?: string
	}
	relationships: {
		field_media_image: {
			localFile: {
				extension: string
				publicURL: string
			}
		}
	}
}

type Fluid = {
	fluid: FluidObject
}
export type Fixed = {
	fixed: FixedObject
}
type ImageSharp = Fluid | Fixed

export const isFluid = (c: ImageSharp): c is Fluid => 'fluid' in c

export interface DrupalMediaImage extends DrupalMediaBase {
	__typename: 'media__image'
	field_media_image: {
		alt?: string
	}
	relationships: {
		field_media_image: {
			localFile: {
				childImageSharp: {
					fluid: FluidObject
					fixed: FixedObject
				}
			}
		}
	}
}

export interface DrupalMediaLogo extends DrupalMediaBase {
	__typename: 'media__logo'
	field_media_image: {
		alt?: string
	}
	fields: {
		link: {
			uri: string
			title: string
		}
	}
	relationships: {
		field_media_image: {
			localFile: {
				childImageSharp: {
					fluid: FluidObject
					fixed: FixedObject
				}
			}
		}
	}
}

export interface DrupalMediaVideo extends DrupalMediaBase {
	__typename: 'media__remote_video'
	name: string
	field_media_oembed_video: string
	relationships: {
		thumbnail: {
			localFile: {
				childImageSharp: {
					original: {
						width: number
						height: number
					}
					fixed: {
						base64: string
					}
				}
			}
		}
	}
}

export type DrupalBanner = DrupalMediaImage

/**
 * Media field that can have different media types.
 *
 * Fields using this type need to be preprocessed in Gatsby's
 * `createSchemaCustomization` hook in `gatsby-node.js`.
 */
export type DrupalMedia = DrupalMediaGraphics | DrupalMediaImage | DrupalMediaLogo | DrupalMediaVideo
