import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import { SelectedFilters } from '@appbaseio/reactivesearch'
import ClearAll from './ClearAll'
import Chips from './Chips'

const Container = styled.div`
	overflow-x: auto;
`
const FilterContainer = styled.div`
	display: flex;
	flex-direction: row;
	margin: 30px 0 0;
	${media.up('md')} {
		flex-wrap: wrap;
	}
`

const FiltersSelected: React.FC = () => {
	return (
		<Container>
			<SelectedFilters
				showClearAll={true}
				clearAllLabel="Tyhjennä suodattimet"
				render={({ selectedValues, setValue, clearValues }) => {
					const filters = Object.keys(selectedValues).filter(
						key => selectedValues[key] && selectedValues[key].value?.length && key !== 'resultssortOption'
					)

					const searchboxFilter = selectedValues?.searchbox?.value ? 1 : 0
					const showClearAll = filters.length > 0
					const filtersCount = (selectedValues?.checkbox?.value?.length ?? 0) + searchboxFilter ?? 0

					const clearAll = () => {
						clearValues()
					}
					return (
						<FilterContainer>
							<ClearAll showClearAll={showClearAll} setValue={clearAll} filtersCount={filtersCount} />
							<Chips componentId="checkbox" filters={selectedValues.checkbox?.value} setValue={setValue} />
						</FilterContainer>
					)
				}}
			/>
		</Container>
	)
}

export default FiltersSelected
