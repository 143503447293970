import React, { useEffect, useLayoutEffect, useRef, useState } from 'react'
import { ReactiveList } from '@appbaseio/reactivesearch'
import { RecipeResult } from '../paragraphs/RecipeSearch'
import Grid from './Grid'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import Loader from './Loader'
import FiltersSelected from './SelectedFilters'

export const StyledReactiveList = styled(ReactiveList)`
	font-size: 14px;
	.button {
		display: inline-flex;
		&:first-of-type {
			font-size: 0;
			&:after {
				content: '<';
				font-size: 20px;
				opacity: 1;
			}
		}
		&:last-of-type {
			font-size: 0;
			&:after {
				content: '>';
				font-size: 20px;
				opacity: 1;
			}
		}
	}
	.pagination {
		display: flex;
		text-align: end;
		margin: 20px 0;
		justify-content: flex-end;
		flex-wrap: wrap;
		${media.up('sm')} {
			margin: 40px 0 10px;
		}
	}
	.sortOptions {
		position: absolute;
		top: -5px;
		outline: transparent;
		font-size: 0.9rem;
		font-weight: 600;
		margin-right: -12px;
		margin-bottom: 5px;
		.option {
			min-height: 1.6rem;
			font-size: 0.9rem;
		}
	}
	.resultStats {
		margin-bottom: 6px;
	}
`
const NumberOfResults = styled.span`
	font-size: 0.9rem;
	margin-bottom: -25px;
	font-weight: 600;
`
const NoResults = styled.div`
	display: flex;
	width: 100%;
	height: 300px;
	align-items: center;
	justify-content: center;
`
const NoResultsText = styled.span``

export type ListProps = {
	componentId: string
	dataField: string
	changed: string
	title_string: string
}

const List: React.FC<ListProps> = props => {
	const { componentId, dataField, changed, title_string } = props
	const [count, setCount] = useState<number>()
	const [height, setHeight] = useState(0)
	const ref = useRef<HTMLDivElement>(null)

	useEffect(() => {
		const timeout = setTimeout(() => {
			setHeight(ref.current?.clientHeight ?? 300)
		}, 300)
		return () => {
			clearTimeout(timeout)
		}
	})

	const renderResultStats = ({ resultStats }: { resultStats: { numberOfResults: number } }) => {
		const { numberOfResults } = resultStats
		setCount(numberOfResults)
	}

	const renderNoResults = () => {
		return (
			<NoResults>
				<NoResultsText>Ei tuloksia näillä suodatusvalinnoilla.</NoResultsText>
			</NoResults>
		)
	}
	const [isMobile, setIsMobile] = useState(false)
	useLayoutEffect(() => {
		setIsMobile(window.innerWidth < 540)
		function reportWindowSize() {
			setIsMobile(window.innerWidth < 540)
		}

		window.onresize = reportWindowSize
	})

	return (
		<>
			<NumberOfResults>{`${count} tulosta`}</NumberOfResults>
			<FiltersSelected />
			<StyledReactiveList
				componentId={componentId}
				dataField={dataField}
				showResultStats={false}
				onData={renderResultStats}
				renderNoResults={renderNoResults}
				URLParams
				sortOptions={[
					{
						label: 'Viimeksi muokatut',
						dataField: changed,
						sortBy: 'desc'
					},
					{
						label: 'Nimen mukaan',
						dataField: title_string,
						sortBy: 'asc'
					}
				]}
				size={isMobile ? 20 : 40}
				pagination={false}
				infiniteScroll={true}
				innerClass={{
					button: 'button',
					resultStats: 'resultStats',
					resultsInfo: 'resultsInfo',
					pagination: 'pagination',
					sortOptions: 'sortOptions'
				}}
				react={{
					and: ['searchbox', 'checkbox']
				}}
				render={({
					data,
					loading,
					error
				}: {
					data: RecipeResult[]
					loading: boolean
					error: unknown
					resultStats: {
						numberOfResults: number
					}
				}) => (
					<>
						{loading && <Loader divHeight={height} />}
						{error && <p>Tapahtui virhe</p>}
						<Grid recipes={data} />
					</>
				)}
			/>
		</>
	)
}
export default List
