import React from 'react'
import { graphql } from 'gatsby'

import { ParagraphComponent, Paragraph } from './types'
import Recipes from '../blocks/Recipes'

interface SpecialEmbedParagraph extends Paragraph {
	field_embed: string
}

const SpecialEmbedComponents: { [key: string]: JSX.Element } = {
	recipe_search: <Recipes />
}

const SpecialEmbed: ParagraphComponent<'paragraph__special_embed ', SpecialEmbedParagraph> = ({ paragraph }) => {
	// This should fail Gatsby build if drupal is giving this component invalid values.
	if (!SpecialEmbedComponents[paragraph.field_embed]) {
		throw new Error(`Special embed ${paragraph.field_embed} is invalid`)
	}

	return SpecialEmbedComponents[paragraph.field_embed]
}

export default SpecialEmbed

export const query = graphql`
	fragment SpecialEmbedParagraph on paragraph__special_embed {
		drupal_internal__id
		field_embed
	}
`
