import React from 'react'
import styled from '@emotion/styled'
import Titlebar from './Titlebar'
import useClickOutside from '../../hooks/useClickOutside'
import Filter from './Filter'
import { media } from '../../style/variables'

const Container = styled.section<{ open: boolean }>`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;
	border-radius: 1px;
	box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.09);
	position: fixed;
	transition: left 0.6s;
	top: 0;
	left: ${p => (p.open ? 0 : '-576px')};
	z-index: 1100;
	margin-right: 30px;
	${media.up('sm')} {
		max-width: 310px;
		flex: 0 0 300px;
	}
	${media.up('lg')} {
		position: static;
		box-shadow: none;
		display: flex;
		z-index: 0;
		flex: 0 0 270px;
	}
`
const ScrollContainer = styled.div`
	overflow: auto;
	height: 100%;
	width: 100%;
	${media.up('lg')} {
		overflow: visible;
	}
`

const AttributeWrapper = styled.div`
	margin: 20px;
	${media.up('lg')} {
		margin: 3px 0px;
	}
`

export type SidebarProps = {
	open: boolean
	onHandleClose: () => void
	isSubCategory?: boolean
}

const Sidebar: React.FC<SidebarProps> = props => {
	const { open, onHandleClose } = props
	const ref = useClickOutside<HTMLDivElement>(onHandleClose)

	return (
		<Container open={open} ref={ref}>
			<Titlebar onHandleClose={onHandleClose} open={open} />
			<ScrollContainer>
				<AttributeWrapper>
					<Filter componentId="checkbox" dataField="tag_name_string" />
				</AttributeWrapper>
			</ScrollContainer>
		</Container>
	)
}

export default Sidebar
