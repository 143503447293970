import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'

const LoaderContainer = styled.div`
	margin: 50px auto;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: rgb(var(--clr-primary));
	background: linear-gradient(to right, rgb(var(--clr-primary)), 10%, #a2659a 43%, rgba(0 0 0 / 0) 52%),
		linear-gradient(339deg, #a2659a 23%, hsla(0, 0%, 100%, 0.6) 38%) 100% 0%;
	position: relative;
	animation: load3 1.4s infinite linear;
	transform: translateZ(0);
	&:before {
		width: 50%;
		height: 50%;
		background: rgb(var(--clr-primary)) 10%;
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	}
	&:after {
		background: white;
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	@keyframes load3 {
		0% {
			transform: rotate(0deg);
		}
		100% {
			transform: rotate(360deg);
		}
	}
	${media.up('md')} {
		width: 50px;
		height: 50px;
	}
`
export type LoadingSpinnerProps = {
	divHeight?: number
}

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({ divHeight }) => {
	return (
		<div style={{ height: divHeight }}>
			<LoaderContainer></LoaderContainer>
		</div>
	)
}

export default LoadingSpinner
