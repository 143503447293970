import React, { useContext } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { WebformCustomComponent } from 'gatsby-drupal-webform'

import DrupalContext from '../../../context/drupal'
import { GraphQLGroup, pickGroup } from '../../../utils'
import { DrupalTaxonomyTerm } from '../../../drupal'
import WebformSelect from './WebformSelect'

type StaticQueryProps = {
	[elementName: string]: {
		group: GraphQLGroup<DrupalTaxonomyTerm>[]
	}
}

function pickVocabulary(name: string, language: string, vocabularies: StaticQueryProps) {
	if (name in vocabularies) {
		const vocabulary = pickGroup(language, vocabularies[name].group)

		if (vocabulary) {
			return vocabulary
		}
	}

	throw Error(`Could not find ${language} vocabulary for ${name} element`)
}

/**
 * Query vocabularies must by keyd by element name.
 *
 * @todo How to make drupal return taxonomy terms only in current language.
 */
const WebformEntitySelect: WebformCustomComponent = ({ element, error }) => {
	const { langcode } = useContext(DrupalContext)
	const vocabularies: StaticQueryProps = useStaticQuery(graphql`
		{
			diet: allTaxonomyTermDiet(sort: { fields: weight }) {
				group(field: langcode) {
					fieldValue
					nodes {
						langcode
						name
						drupal_internal__tid
					}
				}
			}
			main_reason: allTaxonomyTermMainReason(sort: { fields: weight }) {
				group(field: langcode) {
					fieldValue
					nodes {
						langcode
						name
						drupal_internal__tid
					}
				}
			}
			life_situation: allTaxonomyTermLifeSituation(sort: { fields: weight }) {
				group(field: langcode) {
					fieldValue
					nodes {
						langcode
						name
						drupal_internal__tid
					}
				}
			}
		}
	`)

	/**
	 * @todo Investigate if there is anything to gain from using useMemo here.
	 */
	const vocabulary = pickVocabulary(element.name, langcode, vocabularies)
	const options = vocabulary.map(term => ({
		value: term.drupal_internal__tid.toString(),
		label: term.name
	}))

	return <WebformSelect error={error} element={{ ...element, options }} />
}

export default WebformEntitySelect
