import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import CloseIcon from '../Icon/Close'
import TrashIcon from '../Icon/Trash'

const StyledButton = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 4px 8px;
	border-radius: 50px;
	color: rgb(var(--clr-primary));
	border: solid 1px #f5f3f3;
	background-color: #f5f3f3;
	font-weight: 500;
	letter-spacing: 0.4px;
	font-size: 12px;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.4s;
	z-index: 1;
	margin-right: 5px;
	margin-bottom: 5px;
	&:first-of-type {
		margin-left: 0;
	}
	&:hover {
		background-color: rgb(var(--clr-primary)); //#ba62ae;
		border: solid 1px rgb(var(--clr-primary));
		color: white;
		span {
			color: white;
		}
		svg {
			color: white;
		}
		&:before {
			width: 100%;
		}
	}
	svg {
		height: 14px;
		width: auto;
		margin-left: 3px;
		color: black;
		${media.up('sm')} {
			height: 16px;
		}
	}
`
const StyledSpan = styled.span`
	padding-left: 5px;
	color: rgb(var(--clr-primary));
	font-weight: 400;
`

export type ChipProps = {
	text?: string
	onClick?: () => void
	hideCloseIcon?: boolean
	filtersCount?: number
}

const Chip: React.FC<ChipProps> = props => {
	const { text, onClick, hideCloseIcon, filtersCount } = props

	return (
		<StyledButton onClick={onClick}>
			{text}
			{!hideCloseIcon ? (
				<CloseIcon />
			) : (
				<>
					<StyledSpan>{filtersCount}</StyledSpan>
					<TrashIcon />
				</>
			)}
		</StyledButton>
	)
}

export default Chip
