import React from 'react'
import styled from '@emotion/styled'
import { LazyLoadImage, ScrollPosition } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Link } from 'gatsby'
import Flag from './Flag'

const StyledLink = styled(Link)`
	min-height: unset;
	position: relative;
	display: flex;
	flex-direction: column;
	border-radius: 3px;
	box-shadow: 0 2px 25px 0 rgba(0 0 0 / 5%);
	will-change: transform, box-shadow;
	transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
	cursor: pointer;
	text-decoration: none;
	&:hover {
		transform: translateY(-2px);
		box-shadow: 0 0px 20px rgb(0 0 0 / 25%), 0 7px 10px rgb(0 0 0 / 22%);
		text-decoration: none;
	}
	&:active {
		transform: translateY(0px);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
	}
	background-color: white;
`
const AttributeWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin: 7px 0px 0px 10px;
`
const ImageWrapper = styled.div`
	position: relative;
	overflow: hidden;
	@supports not (aspect-ratio: auto) {
		&:after {
			content: '';
			height: 0;
			width: 0;
			display: block;
			z-index: -1;
			padding-top: 100%;
		}
		span {
			position: absolute;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}
	}
	span {
		display: block !important;
	}
`
const Image = styled(LazyLoadImage)`
	display: block;
	object-fit: cover;
	aspect-ratio: 1 / 1;
	width: 100%;
	height: 100%;
	border-radius: 3px 3px 0 0;
`
const TitleWrapper = styled.div`
	position: relative;
	display: flex;
	justify-content: flex-start;
	min-height: 40px;
`
const Title = styled.h2`
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	font-weight: 600;
	line-height: 1.4;
	letter-spacing: 1.1px;
	margin: 0;
	overflow: hidden;
	color: #101010;
	text-decoration: none;
	font-size: 12px;
	padding: 3px 0;
`
const FlagText = styled.span`
	display: block;
	line-height: 1;
	font-weight: 600;
	overflow: hidden;
	font-size: 10px;
	color: rgb(var(--clr-primary));
`
export type CardProps = {
	title?: string
	image?: string
	href: string
	id?: string
	attributes: string
	scrollPosition: ScrollPosition | undefined
}

const Card: React.FC<CardProps> = props => {
	const { id, image, href, title, attributes, scrollPosition } = props
	const isGlutenFree = attributes.includes('Gluteenittomia sekä gluteenittomiksi muunneltavia reseptejä')
	const isNutFree = attributes.includes('Pähkinättömiä ja pähkinättömiksi muunneltavia reseptejä')
	const isSoyaFree = attributes.includes('Soijattomia ja soijattomiksi muunneltavia reseptejä')
	const extraPadding = isGlutenFree || isNutFree || isSoyaFree ? '2px 10px 10px' : '27px 10px 10px'
	return (
		<StyledLink id={id} to={href} tabIndex={0}>
			<ImageWrapper>
				<Image src={image} alt="Reseptikuva" effect="blur" scrollPosition={scrollPosition} />
			</ImageWrapper>
			<AttributeWrapper>
				{isNutFree && (
					<Flag tooltip="Pähkinätön ja pähkinättömäksi muunneltava">
						<FlagText>P</FlagText>
					</Flag>
				)}
				{isGlutenFree && (
					<Flag tooltip="Gluteeniton tai gluteenittomaksi muunneltava">
						<FlagText>G</FlagText>
					</Flag>
				)}
				{isSoyaFree && (
					<Flag tooltip="Soijaton tai soijattomaksi muunneltava">
						<FlagText>S</FlagText>
					</Flag>
				)}
			</AttributeWrapper>
			<TitleWrapper style={{ padding: extraPadding }}>
				<Title>{title}</Title>
			</TitleWrapper>
		</StyledLink>
	)
}

export default Card
