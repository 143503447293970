import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import FilterIcon from '../Icon/Filter'

const StyledButton = styled.button`
	position: relative;
	overflow: hidden;
	display: flex;
	align-items: center;
	justify-content: center;
	align-items: center;
	border-radius: 50px;
	padding: 10px 16px;
	border: none;
	color: white;
	background-image: linear-gradient(to right, rgb(var(--clr-primary)));
	overflow-y: auto;
	box-shadow: 0px 3px 10px rgb(0 0 0 / 25%), 0px 3px 10px #fafafa;
	background-size: 200% auto;
	font-weight: 600;
	letter-spacing: 0.4px;
	font-size: 14px;
	white-space: nowrap;
	cursor: pointer;
	transition: all 0.4s;
	margin-bottom: 8px;
	${media.up('sm')} {
		border: none;
		background-color: rgb(var(--clr-primary));
		background-image: none;
		box-shadow: unset;
		margin-bottom: 0;
	}
	&:before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 0%;
		bottom: 0;
		height: 100%;
		background-color: #333334;
		transition: all 0.4s;
		z-index: -1;
		border-radius: 50px;
		border: none;
	}
	&:hover {
		color: white;
		&:before {
			width: 100%;
		}
	}
	${media.up('lg')} {
		display: none;
	}
`

const Icon = styled(FilterIcon)`
	height: 24px;
	width: auto;
	margin-right: 12px;
`
export type FilterButtonProps = {
	text?: string
	onClick?: () => void
}

const FilterButton: React.FC<FilterButtonProps> = props => {
	const { text, onClick } = props

	return (
		<StyledButton onClick={onClick}>
			<Icon />
			{text}
		</StyledButton>
	)
}

export default FilterButton
