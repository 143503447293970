import React from 'react'
import Select from 'react-select'

import { SelectStyle } from '../../../style/form'
import { WebformCustomComponent, WebformElementWrapper, useWebformElement } from 'gatsby-drupal-webform'

/**
 * @todo translate webform select: https://react-select.com/props
 *  - noOptionsMessage
 *  - a11y text: can that be translated - maybe not?
 */
const WebformSelect: WebformCustomComponent = ({ element, error }) => {
	const id = `form-${element.name.replace('_', '-')}`
	const [selectProps, settings] = useWebformElement(element, {
		className: 'form-select',
		name: element.name,
		id
	})

	return (
		<WebformElementWrapper settings={settings} error={error} labelFor={id}>
			<Select {...selectProps} styles={SelectStyle} options={element.options} />
		</WebformElementWrapper>
	)
}

export default WebformSelect
