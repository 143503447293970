import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Paragraph, ParagraphComponent } from './types'
import { media } from '../../style/variables'
import { DrupalMediaImage } from '../../drupal'
import Media from '../fields/Media'

export interface MediaGridParagraph extends Paragraph {
	relationships: {
		field_media: DrupalMediaImage[]
	}
}

/**
 * @see https://caniuse.com/#feat=css-grid
 */
const StyledContainer = styled.div`
	display: flex;

	.media {
		flex-grow: 1;
	}

	.gatsby-image-wrapper {
		height: 460px;
	}

	${media.down('sm')} {
		flex-direction: column;
		.gatsby-image-wrapper {
			height: 360px;
		}
	}
`

const MediaGrid: ParagraphComponent<'paragraph__media_grid', MediaGridParagraph> = ({ paragraph }) => {
	return (
		<section className="paragraph full-width">
			<StyledContainer>
				{paragraph.relationships.field_media.map(media => (
					<Media key={media.drupal_internal__mid} source={media} />
				))}
			</StyledContainer>
		</section>
	)
}

export default MediaGrid

export const query = graphql`
	fragment MediaGridParagraph on paragraph__media_grid {
		drupal_internal__id
		relationships {
			field_media {
				__typename
				drupal_internal__mid
				field_media_image {
					alt
				}
				relationships {
					field_media_image {
						localFile {
							childImageSharp {
								fluid {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
