import React from 'react'
import Chip from './Chip'

type ChipsProps = {
	filters: string[]
	setValue: (n: string, s: unknown[]) => void
	componentId: string
}

const Chips: React.FC<ChipsProps> = ({ filters, setValue, componentId }) => {
	const clearFilter = (filter: unknown) => {
		setValue(
			componentId,
			filters.filter(v => v !== filter)
		)
	}
	return (
		<>
			{filters?.map(filter => (
				<Chip key={filter} onClick={() => clearFilter(filter)} text={filter} />
			))}
		</>
	)
}

export default Chips
