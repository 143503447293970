import React from 'react'
import styled from '@emotion/styled'
import { media } from '../../style/variables'
import ArrowUpIcon from '../Icon/ArrowUp'
import { useCallback, useEffect, useState } from 'react'

const ScrollTopButtonWrapper = styled.div`
	position: fixed;
	bottom: 22px;
	z-index: 1000;
	display: flex;
	align-self: flex-end;
	right: 20px;
	backdrop-filter: 1;
	${media.up('sm')} {
		right: 40px;
	}
`

const ButtonBase = styled.button<{ showScroll: boolean }>`
	overflow: hidden;
	display: ${p => (p.showScroll ? 'flex' : 'none')};
	align-items: center;
	justify-content: center;
	padding: 2px;
	align-items: center;
	border-radius: 50px;
	color: white;
	box-shadow: 0px 3px 10px rgb(0 0 0 / 25%), 0px 3px 10px #fafafa;
	cursor: pointer;
	background-color: rgb(var(--clr-primary));
	border-color: rgb(var(--clr-primary));
	&:hover {
		color: white;
		background-color: #333334;
	}
	${media.up('sm')} {
		margin-bottom: 0;
		padding: 2px;
	}
`

const Icon = styled(ArrowUpIcon)`
	height: 34px;
	width: auto;
	color: white;
`
export type ScrollTopButtonProps = {
	onClick?: () => void
	showScroll?: boolean
}

const ScrollTopButton: React.FC<ScrollTopButtonProps> = () => {
	const [showScroll, setShowScroll] = useState(false)

	const checkScrollTop = useCallback(() => {
		if (!showScroll && window?.pageYOffset > 350) {
			setShowScroll(true)
		} else if (showScroll && window?.pageYOffset <= 350) {
			setShowScroll(false)
		}
	}, [showScroll])

	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' })
	}
	useEffect(() => {
		if (window) {
			window.addEventListener('scroll', checkScrollTop, true)
		}
		return () => {
			window.removeEventListener('scroll', checkScrollTop, true)
		}
	}, [checkScrollTop])

	return (
		<ScrollTopButtonWrapper>
			<ButtonBase onClick={scrollTop} showScroll={showScroll}>
				<Icon />
			</ButtonBase>
		</ScrollTopButtonWrapper>
	)
}

export default ScrollTopButton
