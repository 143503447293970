/* eslint-disable @typescript-eslint/no-non-null-assertion */

import React, { useState, useEffect, useRef } from 'react'
import { graphql } from 'gatsby'
import CountUp from 'react-countup'
import styled from '@emotion/styled'
import axios from 'axios'

import useIntersectionObserver from '../../../hooks/useIntersectionObserver'
import { Paragraph, ParagraphComponent } from '../types'
import Container from '../../Container'

export interface SignupCountParagraph extends Paragraph {
	field_title: string
}

const StyledContainer = styled(Container)`
	display: flex;
	align-items: center;
	font-size: 2rem;
	margin-bottom: 2rem;
	justify-content: center;

	.count-up {
		color: rgb(var(--clr-secondary));
		border-bottom: 4px solid rgb(var(--clr-secondary));
	}
`

const COUNTER_REPLACE_PATTERN = '@counter'

const useVeganCount = (): [number, React.RefObject<HTMLElement>] => {
	const [count, setCount] = useState(0)
	const [isVisible, setVisible] = useState(false)

	const viewport = useRef<HTMLElement>(null)
	const observer = useIntersectionObserver(
		(entries, observer) => {
			entries.forEach(entry => {
				if (entry.intersectionRatio == 1) {
					setVisible(true)
					observer.unobserve(entry.target)
				}
			})
		},
		{
			root: null,
			rootMargin: '0px',
			threshold: [0.0, 0.75]
		}
	)

	useEffect(() => {
		observer.observe(viewport.current!)

		/**
		 * Fetch count from drupal endpoint.
		 * @todo: Would be really cool to have this update every few minutes. :)
		 */
		const fetchData = async () => {
			const result = await axios.get(`${process.env.GATSBY_DRUPAL_HOST}/vc/counter`)

			setCount(parseInt(result.data, 10))
		}

		fetchData()

		return () => observer.disconnect()
	}, [])

	return [isVisible ? count : 0, viewport]
}

const SignupCount: ParagraphComponent<'paragraph__signup_count', SignupCountParagraph> = ({ paragraph }) => {
	const [count, viewport] = useVeganCount()
	const [prefix, suffix] = paragraph.field_title.split(COUNTER_REPLACE_PATTERN)

	return (
		<section className="paragraph" ref={viewport}>
			<StyledContainer>
				{count === 0 ? (
					// Data not ready yet
					<span className="signup-count loading" />
				) : (
					<span className="signup-count ready">
						{prefix} <CountUp className="count-up" end={count} /> {suffix}
					</span>
				)}
			</StyledContainer>
		</section>
	)
}

export default SignupCount

export const query = graphql`
	fragment SignupCountParagraph on paragraph__signup_count {
		drupal_internal__id
		field_title
	}
`
