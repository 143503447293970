import React, { useEffect, useState } from 'react'
import { graphql, Link } from 'gatsby'
import styled from '@emotion/styled'
import { Paragraph, ParagraphComponent } from './types'
import { DrupalNodeTeaser, DrupalMediaImage } from '../../drupal'
import { dimensions, media } from '../../style/variables'
import Container from '../Container'
import Media from '../fields/Media'
import InfoMenu from '../infoMenu/InfoMenu'
import CategoryDescription from '../InfoCategoryDescription'

export type Tag = {
	id: string
	name: string
	description: {
		processed: string | null
	}
	relationships: {
		field_category_image?: DrupalMediaImage
	}
}

interface ArticleNodeTeaser extends DrupalNodeTeaser {
	__typename: 'node__article'
	fields: {
		summary: string
	}
	relationships: {
		field_media?: DrupalMediaImage
		field_category_tags?: Tag[]
	}
}

interface RecipeNodeTeaser extends DrupalNodeTeaser {
	__typename: 'node__recipe'
	fields: {
		summary: string
	}
	relationships: {
		field_media?: DrupalMediaImage
	}
}

type Content = ArticleNodeTeaser | RecipeNodeTeaser

export interface FeaturedContentParagraph extends Paragraph {
	relationships: {
		field_reference: Content[]
	}
}

const StyledGridContainer = styled.div`
	margin-top: 20px;
	display: grid;
	align-items: center;
	grid-template-columns: repeat(auto-fit, minmax(1 240px, 1fr));
	grid-gap: 10px;
	${media.up('sm')} {
		grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
		grid-gap: ${dimensions.containerPadding}rem;
	}
	.content {
		height: 100%;
	}
	.content-body {
		padding: 0.8rem;
		flex: 1 1 auto;
		min-height: 124px;
		${media.up('sm')} {
			padding: ${dimensions.containerPadding}rem;
		}
	}
	.btn,
	button {
		color: #ffffff;
	}
`
const Card = styled(Link)`
	box-shadow: 0 2px 20px 0 rgba(101, 101, 101, 0.09);
	will-change: transform, box-shadow;
	display: flex;
	flex-direction: column;
	flex: 2 1 auto;
	font-size: 0.9rem;
	margin-bottom: 0px;
	max-width: 550px;
	transition: transform 0.6s cubic-bezier(0.25, 0.8, 0.25, 1), box-shadow 0.6s cubic-bezier(0.25, 0.8, 0.25, 1);
	border-radius: 3px;
	overflow: hidden;
	&:hover {
		text-decoration: none;
		transform: translateY(-2px);
		box-shadow: 0 0px 20px rgb(0 0 0 / 25%), 0 7px 10px rgb(0 0 0 / 22%);
	}
	&:active {
		transform: translateY(0px);
		box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.07);
	}
	${media.down('sm')} {
		display: flex;
		flex-direction: row-reverse;
	}
`
const ImageWrapper = styled.div`
	${media.down('sm')} {
		flex: 0 0 35%;
		div {
			height: 100%;
			aspect-ratio: 1/1;
		}
	}
`
const CardTextWrapper = styled.div`
	padding: 1rem;
`
const Styledh2 = styled.h2`
	color: #000;
	font-size: 1.2rem;
	margin-bottom: 0.3rem;
	letter-spacing: 0.2px;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	${media.up('sm')} {
		font-size: 1.6rem;
		margin-bottom: 0.3rem;
		letter-spacing: 0.2px;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		color: #000000;
	}
	${media.up('lg')} {
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		color: #000;
		letter-spacing: 0.5px;
		font-size: 2rem;
		margin-bottom: 0.8rem;
		font-size: 2rem;
	}
`
const StyledBody = styled.p`
	color: #000000;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	text-overflow: ellipsis;
	overflow: hidden;
	margin-bottom: 0;
	font-size: 0.8rem;
	${media.up('sm')} {
		display: -webkit-box;
		-webkit-line-clamp: 4;
		-webkit-box-orient: vertical;
		text-overflow: ellipsis;
		overflow: hidden;
		margin-bottom: 0;
		font-size: 0.9rem;
		color: #000000;
	}
	${media.up('md')} {
		display: -webkit-box;
		-webkit-line-clamp: 10;
		-webkit-box-orient: vertical;
		text-overflow: none;
		overflow: hidden;
		margin-bottom: 0;
		font-size: 1rem;
		color: #000000;
	}
`

const StyledContainer = styled(Container)`
	position: relative;
	margin-bottom: 30px;
	padding-right: 10px;
	padding-left: 10px;
	${media.up('md')} {
		margin-bottom: 50px;
		padding-right: 0;
		padding-left: 0;
	}
`
const StyledMedia = styled(Media)`
	border-radius: 3px 3px 0 0;
`

function renderArticles(content: Content) {
	const className = `content content-${content.__typename.replace('node__', '')}`

	return (
		<Card to={content.path.alias} rel="nofollow" className={className}>
			<ImageWrapper className="content-image">
				<StyledMedia source={content.relationships.field_media} />
			</ImageWrapper>
			<CardTextWrapper className="content-body">
				<Styledh2>{content.title}</Styledh2>
				<div>{content.fields.summary ? <StyledBody>{content.fields.summary}</StyledBody> : ''}</div>
			</CardTextWrapper>
		</Card>
	)
}

const getTagFromUrl = () => {
	const url = new URL(window.location.href)
	const params = new URLSearchParams(url.search)
	return params.get('tag')
}

const setTagToUrl = (tagId: string | null | undefined) => {
	const url = new URL(window.location.href)
	const params = new URLSearchParams(url.search)
	if (tagId) {
		params.set('tag', tagId ?? '')
	} else {
		params.delete('tag')
	}
	window.history.pushState({}, '', `${location.pathname}?${params}`)
}

const FeaturedContent: ParagraphComponent<'paragraph__featured_content', FeaturedContentParagraph> = ({
	paragraph
}) => {
	const filteredContents = paragraph.relationships.field_reference.map(article => {
		if ('field_category_tags' in article.relationships) {
			// eslint-disable-next-line @typescript-eslint/camelcase
			const field_category_tags = article.relationships?.field_category_tags?.filter(
				({ name }) => !(name.indexOf('Dummy') > -1)
			)
			return {
				...article,
				relationships: {
					...article.relationships,
					field_category_tags
				}
			}
		}
		return article
	})
	if (process.env.GATSBY_LANGUAGE !== 'fi') {
		return <DefaultContent contents={filteredContents} />
	}
	return <FilteredContent contents={filteredContents} />
}

const DefaultContent = ({ contents }: { contents: Content[] }) => (
	<section className="paragraph">
		<StyledContainer>
			<StyledGridContainer className="featured-content">
				{contents.map(content => (
					<React.Fragment key={content.drupal_internal__nid}>{renderArticles(content)}</React.Fragment>
				))}
			</StyledGridContainer>
		</StyledContainer>
	</section>
)

const FilteredContent = ({ contents }: { contents: Content[] }) => {
	const [selectedTag, setTag] = useState<Tag | null>()

	const setSelectedTag = (t: Tag | null | undefined) => {
		setTagToUrl(t?.id)
		setTag(t)
	}
	const uniqueTags = contents.reduce((prev, article) => {
		if ('field_category_tags' in article.relationships) {
			article.relationships.field_category_tags?.forEach(tag => {
				if (!prev.find(({ id }) => id === tag.id)) {
					prev.push(tag)
				}
			})
		}
		return prev
	}, [] as Tag[])

	const findAndSetTagFromUrlParams = () => {
		const tagIdFromUrl = getTagFromUrl()
		const _tag = uniqueTags.find(({ id }) => id === tagIdFromUrl)
		setTag(_tag)
	}

	useEffect(() => {
		window.addEventListener('popstate', () => {
			findAndSetTagFromUrlParams()
		})
		findAndSetTagFromUrlParams()
	}, [])

	const unfiltered = contents
	const filtered = selectedTag
		? unfiltered.filter(card => {
				if ('field_category_tags' in card.relationships) {
					return card.relationships.field_category_tags?.find(({ id }) => id === selectedTag.id) ?? false
				}
				return false
		  })
		: []

	return (
		<section className="paragraph">
			<StyledContainer>
				{selectedTag ? (
					<>
						<CategoryDescription tag={selectedTag} onClickButton={() => setSelectedTag(undefined)} />
						<StyledGridContainer className="featured-content">
							{filtered.map(content => (
								<React.Fragment key={content.drupal_internal__nid}>{renderArticles(content)}</React.Fragment>
							))}
						</StyledGridContainer>
					</>
				) : (
					<InfoMenu tags={uniqueTags} onClick={setSelectedTag} />
				)}
			</StyledContainer>
		</section>
	)
}

export default FeaturedContent

export const query = graphql`
	fragment FeaturedContentParagraph on paragraph__featured_content {
		drupal_internal__id
		relationships {
			field_reference {
				__typename
				... on node__article {
					drupal_internal__nid
					title
					path {
						alias
					}
					fields {
						summary
					}
					relationships {
						field_media {
							__typename
							...MediaImageThumbnail
						}
						field_category_tags {
							name
							id
							description {
								processed
							}
							relationships {
								field_category_image {
									__typename
									...MediaImageThumbnail
								}
							}
						}
					}
				}
				... on node__recipe {
					drupal_internal__nid
					title
					path {
						alias
					}
					fields {
						summary
					}
					relationships {
						field_media {
							__typename
							...MediaImageThumbnail
						}
					}
				}
			}
		}
	}
`
