import { keyframes } from '@emotion/core'

export const slideInLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
`
export const slideInRight = keyframes`
  0% {
    transform: translateX(20px);
    opacity: 0;
  }
`
export const slideInUp = keyframes`
  0% {
    transform: translateY(20px);
    opacity: 0;
  }
`
export const slideInDown = keyframes`
  0% {
    transform: translateY(-20px);
    opacity: 0;
  }
`

export const scaleIn = keyframes`
  0% {
    transform: scale(0.99);
    opacity: 0;
  }

`

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

`
