import React, { useLayoutEffect, useRef } from 'react'
import { DataSearch } from '@appbaseio/reactivesearch'
import styled from '@emotion/styled'

const StyledSearch = styled(DataSearch)`
	z-index: 90;
	padding: 0;
	.search-input {
		border-radius: 0px;
		border: transparent;

		border-bottom: 2px solid rgb(var(--clr-primary));
		background-color: #f9f8f8;
	}
	.search-icon {
		height: 14px;
	}
`

type SearchProps = {
	componentId: string
	dataField: string[]
}

const Search: React.FC<SearchProps> = props => {
	const { componentId, dataField } = props
	const inputRef = useRef<HTMLDivElement>(null)

	useLayoutEffect(() => {
		if (typeof window !== 'undefined') {
			const currStickyPos = (inputRef.current?.getBoundingClientRect()?.top ?? 0) + window.pageYOffset
			window.onscroll = function () {
				if (inputRef.current) {
					if (window.pageYOffset > currStickyPos) {
						inputRef.current.style.position = 'fixed'
						inputRef.current.style.left = '0px'
						inputRef.current.style.right = '0px'
						inputRef.current.style.top = '0px'
						document.body.style.marginTop = '42px'
					} else {
						inputRef.current.style.position = 'relative'
						inputRef.current.style.top = 'initial'
						document.body.style.marginTop = '0px'
					}
				}
			}
		}
	}, [])
	return (
		<div ref={inputRef} style={{ zIndex: 90 }}>
			<StyledSearch
				componentId={componentId}
				dataField={dataField}
				placeholder="Haku"
				autosuggest={true}
				showClear={true}
				showDistinctSuggestions={false}
				queryFormat="and"
				fuzziness={'AUTO'}
				URLParams={true}
				innerClass={{
					input: 'search-input',
					icon: 'search-icon'
				}}
			/>
		</div>
	)
}
export default Search
